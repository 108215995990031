<template>
  <div>
    <section
      class="hero is-primary"
      :style="{
        background:
          'linear-gradient(153deg, rgba(47, 181, 67, 1) 25%, rgba(50, 237, 78, 1) 125%)',
      }"
    >
      <div class="hero-body">
        <h1 class="title">Welcome back!</h1>
        <p class="subtitle">Please stand by, you will be redirected soon</p>
      </div>
    </section>
    <section
      class="hero"
    >
      <div class="hero-body">
        <h1 class="title">What's happening?</h1>
        <p class="subtitle">You were sent back from a 3rd party, and we are currently processing this request</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>