<template>
  <footer class="footer">
    <div class="content has-text-centerd">
      <p>
        <strong>Rewards</strong> by <a :href="version ? '' : '//dojnaz.cloud/'">Dojnaz Cloud</a>
      </p>
      <!--<p>
        Some helpful articles
      </p>
      <p>
        <a href="//dojnaz.cloud">How do I use my coins?</a>
      </p>-->
      <p v-if="version">
        <router-link to="/dashboard/debug">{{ version }}</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      version: window.dcloudVersion
        ? `Dojnaz Cloud Desktop v${window.dcloudVersion}`
        : "",
    };
  },
};
</script>

<style scoped>
footer {
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 3rem;
  min-height: 168px;
}
p {
  margin-bottom: 5px !important;
}
</style>