<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <router-view :style="{
      minHeight: 'calc(100vh - 226px)'
    }"/>
    <Footer />
    <notifications group="dojnaz" position="bottom right" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "DojnazCloudCoupons",
  components: {
    Navbar,
    Footer
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
