<template>
  <iframe
    :data-aa="unitId"
    :src="`//acceptable.a-ads.com/${unitId}`"
    style="
      border: 0px;
      padding: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: transparent;
    "
  ></iframe>
</template>

<script>
export default {
  props: {
    unitId: String,
  },
};
</script>

<style>
</style>