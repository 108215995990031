import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Callback from '../views/Callback.vue'
import History from '../views/History.vue'
import Error from '../views/Error.vue'
import api from 'axios'
api.defaults.baseURL = process.env.VUE_APP_API
import store from '../store'
import admins from '../../src/admins.json'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    async beforeEnter(to, from, next) {
      if (store.state.token) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/login',
    name: 'Authentication redirect',
    async beforeEnter(to, from, next) {
      window.location.href = `https://dojnaz.cloud/oauth/authorize.php?client_id=DOJNAZ-CLOUD.lvf9tRunvIQm1mBB5pl5qQ==&redirect_uri=${process.env.VUE_APP_CALLBACK_URL}&response_type=code&scope=openid%20profile%20email&state=dojnazcloud`
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    async beforeEnter(to, from, next) {
      localStorage.clear()
      document.location.href = '/'
    }
  },
  {
    path: '/callback',
    name: 'Authentication callback',
  },
  {
    path: '/callback/discord',
    name: 'Discord callback',
    component: Callback
  },
  {
    path: '/error',
    name: 'Error!',
    component: Error
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: () => import('../views/OfferWall.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard'
            }
          ]
        }
      },
      {
        path: 'discord',
        name: 'Discord',
        component: () => import('../views/Discord.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Discord'
            }
          ]
        }
      },
      {
        path: 'mine',
        name: 'Mining',
        component: () => import('../views/Mining.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Mining'
            }
          ]
        }
      },
      {
        path: 'desktop',
        name: 'Desktop',
        component: () => import('../views/Desktop.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Desktop'
            }
          ]
        }
      },
      {
        path: 'debug',
        name: 'Debug',
        component: () => import('../views/Debug.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Debug'
            }
          ]
        }
      },
      {
        path: 'discord/redirect',
        name: 'Discord Redirect',
        beforeEnter(to, from, next) {
          console.log(process.env)
          window.location.href = `https://discord.com/api/oauth2/authorize?client_id=879373192576237569&redirect_uri=${encodeURIComponent(
            process.env.VUE_APP_DISCORD_REDIRECT
          )}&response_type=code&scope=identify%20guilds.join`
        }
      },
      {
        path: 'theoremreach',
        name: 'Theoremreach',
        component: () => import('../views/Theoremreach.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Surveys'
            },
            {
              name: 'Theoremreach'
            }
          ]
        }
      },
      {
        path: 'cpx',
        name: 'CPX Research',
        component: () => import('../views/CPX Research.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Surveys'
            },
            {
              name: 'CPX Research'
            }
          ]
        }
      },
      {
        path: 'history',
        name: 'History',
        component: () => import('../views/History.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'History'
            }
          ]
        }
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import('../views/Admin.vue'),
        meta: {
          breadcrumb: [
            {
              name: 'Dashboard',
              link: '/dashboard'
            },
            {
              name: 'Admin (UwU)'
            }
          ]
        },
        beforeEnter(_to, _from, next) {
          if (!admins.includes(store.state.email)) {
            next({
              name: 'Home'
            })
            return
          }
          next()
        }
      }
    ]
  },
  {
    path: '*',
    name: '404 Not Found',
    component: Error,
    beforeEnter(from, to, next) {
      next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == "/") return next()
  console.log(`${from.path} => ${to.path}`)
  if ((to.meta.requiresAuth || to.matched[0].meta.requiresAuth) && !store.state.token) {
    return next('/')
  }
  next()
})

router.afterEach((to, from) => {
  $store.dispatch('updateBalance')
  if (to.name == 'Authentication callback') {
    api.get('/auth?code=' + to.query.code).then((res) => {
      console.log(res.data)
      if (!res.data.success) {
        return router.push('/error?message=' + encodeURIComponent(res.data.message))
      }
      store.commit('setToken', res.data.token)
      router.push('/dashboard')
      console.log('ok')
    }).catch((err) => {
      console.error(err)
      router.push('/error?message=Unable to authenticate')
    })
  }
})

router.afterEach((to, from) => {
  if (to.name == 'Discord callback') {
    if (!to.query.code) {
      router.push('/error?message=invalid request')
    }
    api({
      url: `/callback/discord?code=${to.query.code}`,
    })
  }
})

export default router
