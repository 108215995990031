<template>
  <div>
    <section
      class="hero is-primary shadow"
      :style="{
        background:
          'linear-gradient(153deg, rgba(14,154,254,1) 25%, rgba(14,240,254,1) 125%)',
      }"
    >
      <div class="hero-body">
        <h1 class="title">Welcome to the Dojnaz Cloud Rewards Dashboard</h1>
        <p class="subtitle">
          <span
            v-for="(breadcrumb, idx) in breadcrumbList"
            :key="idx"
            @click="routeTo(idx)"
          >
            <span> / </span>
            <span :class="{ linked: !!breadcrumb.link }">{{
              breadcrumb.name
            }}</span>
          </span>
        </p>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src

function updateBalance() {
  this.$store.dispatch("updateBalance");
}

export default {
  name: "Home",
  data() {
    return {
      breadcrumbList: [],
    };
  },
  mounted() {
    this.updateList();
    window.addEventListener("visibilitychange", updateBalance);
    /*(function(s,u,z,p){s.src=u,s.setAttribute('data-zone',z),p.appendChild(s);})(document.createElement('script'),'//inpage-push.com/400/4460193',4460181,document.body||document.documentElement)
    (function(s,u,z,p){s.src=u,s.setAttribute('data-zone',z),p.appendChild(s);})(document.createElement('script'),'//upgulpinon.com/1?z=4460195',4460181,document.body||document.documentElement)*/
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style scoped>
.linked {
  text-decoration: underline;
  cursor: pointer;
}
.shadow {
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
}
</style>
