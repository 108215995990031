<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title" :style="{
        marginRight: '10px'
      }">It appears you're using a VPN!</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <p>This may cause major issues when completing offers such as surveys.</p>
      <br />
      <p>If you only intend on mining and the like, this is not an issue. </p>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>