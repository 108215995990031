<template>
  <div>
    <section
      class="hero is-primary"
      :style="{
        background:
          'linear-gradient(153deg, rgba(254,14,14,1) 18%, rgba(254,199,14,1) 100%)',
      }"
    >
      <div class="hero-body">
        <h1 class="title">This is the error page :D</h1>
        <h1 class="subtitle">{{ message }}</h1>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message:
        this.$router.currentRoute.query.message ||
        this.$router.currentRoute.name ||
        "An unkown error has occured",
    };
  },
};
</script>
