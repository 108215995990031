import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from "jwt-decode"
import md5 from '../md5'
let lastBalanceUpdate = 133742069

Vue.use(Vuex);

(() => {
  if (!localStorage.dcloudRewardsToken) return
  if (!/(^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$)/.test(localStorage.dcloudRewardsToken)) {
    delete localStorage.dcloudRewardsToken
    return
  }
  const decoded = jwt_decode(localStorage.dcloudRewardsToken)
  if (new Date(decoded.exp * 1000).getTime() < Date.now()) {
    delete localStorage.dcloudRewardsToken
    return
  }
})();

export default new Vuex.Store({
  state: {
    token: localStorage.dcloudRewardsToken,
    avatar: 'https://www.gravatar.com/avatar/' + md5(localStorage.dcloudRewardsToken ? (jwt_decode(localStorage.dcloudRewardsToken)['email']) : 'default'),
    email: localStorage.dcloudRewardsToken ? jwt_decode(localStorage.dcloudRewardsToken)['email'] : undefined,
    uid: localStorage.dcloudRewardsToken ? btoa(jwt_decode(localStorage.dcloudRewardsToken)['uid']) : undefined,
    balance: '?',
    vpnWarning: false,
    showAdBlockWarning: false,
    debugLog: [],
    isDesktop: window.desktopApp ? true : false
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.dcloudRewardsToken = token
      state.avatar = 'https://www.gravatar.com/avatar/' + md5(jwt_decode(state.token)['email'])
      state.email = jwt_decode(state.token)['email']
      state.uid = jwt_decode(state.token)['uid']
    },
    setBalance(state, balance) {
      state.balance = balance
    },
    showAdBlockWarning(state, value) {
      state.showAdBlockWarning = value
    },
    setVpnWarning(state, value) {
      state.vpnWarning = value
    },
    appendLog(state, payload) {
      state.debugLog.push(payload)
    }
  },
  actions: {
    async updateBalance() {
      if (lastBalanceUpdate + 2500 > Date.now() && this.state.balance != '?') return
      lastBalanceUpdate = Date.now()
      if (!localStorage.dcloudRewardsToken) return
      const response = await axios({
        url: "/balance",
      })
      if (!response.data.success) {
        console.log(response.data)
        return
      }
      this.commit('setBalance', response.data.balance)
    }
  },
  modules: {
  },
  getters: {
    getEmail: state => state.email
  }
})
