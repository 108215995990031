<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <ClaimVoucher />
        <br>
        <table>
          <tr>
            <th>Description</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
          <tr v-for="(event, idx) in history" :key="idx">
            <td>{{ event.description }}</td>
            <td>{{ event.amount }}</td>
            <td>{{ event.date }}</td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ClaimVoucher from "../components/ClaimVoucher.vue";

export default {
  name: "History",
  components: {
    ClaimVoucher,
  },
  data() {
    return {
      history: [],
    };
  },
  asyncComputed: {
    async history() {
      const response = await axios({
        url: "/history",
      }).catch(console.error);
      if (!response) {
        return [
          {
            description: "Fetch failed :(",
            amount: "Fetch failed",
          },
        ];
      }
      return response.data.history;
    },
  },
  watch: {
    async '$store.state.balance'() {
      const response = await axios({
        url: "/history",
      }).catch(console.error);
      if (!response) {
        return [
          {
            description: "Fetch failed :(",
            amount: "Fetch failed",
          },
        ];
      }
      this.history = response.data.history;
    }
  }
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
table td,
table th {
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0e9afe;
  color: white;
}
</style>
