<template>
  <div class="home">
    <section
      class="hero is-primary"
      :style="{
        background:
          'linear-gradient(153deg, rgba(14,154,254,1) 25%, rgba(14,240,254,1) 125%)',
      }"
    >
      <div class="hero-body">
        <h1 class="title">Welcome to Dojnaz Cloud Rewards</h1>
        <p class="subtitle">Login to get started!</p>
      </div>
    </section>
    <section class="hero">
      <div class="hero-body">
        <p class="title">What is Dojnaz Cloud Rewards?</p>
        <p class="subtitle">
          Dojanz Cloud Rewards is a way to earn coins for free, that are
          directly translated into credit on your Dojnaz Cloud account.
        </p>
      </div>
    </section>
    <!--<AAd unitId="1750331" />-->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import AAd from "@/components/AAd.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
    AAd,
  },
};
</script>
