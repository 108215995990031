import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import axios from 'axios'
import AsyncComputed from 'vue-async-computed'
import Notifications from 'vue-notification'
import jwt_decode from "jwt-decode"
import VueSocketIO from 'vue-socket.io'
import io from 'socket.io-client'
import { InlineSvgPlugin } from 'vue-inline-svg';
import { detectAnyAdblocker } from 'just-detect-adblock'
import util from 'util'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://a29e247e19ed411e9f75009310834114@o212932.ingest.sentry.io/6024720",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "rewards.dojnaz.cloud", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

detectAnyAdblocker().then((detected) => {
  if (detected) {
    if (localStorage.acknowledgedAdBlockerWarning) return
    store.commit('showAdBlockWarning', true)
  }
})

Vue.use(InlineSvgPlugin);
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io(process.env.VUE_APP_API, {
      query: {
        token: localStorage.dcloudRewardsToken
      }
    }),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);

Vue.use(AsyncComputed)
Vue.use(Notifications)
axios.defaults.baseURL = process.env.VUE_APP_API
axios.interceptors.request.use((request) => {
  if (localStorage.dcloudRewardsToken && jwt_decode(localStorage.dcloudRewardsToken).exp < Date.now() / 1000)
    delete localStorage.dcloudRewardsToken

  request.headers['authorization'] = localStorage.dcloudRewardsToken
  return request
}, error => {
  return Promise.reject(error)
})
axios.interceptors.response.use((response) => {
  if (!response.data.success) {
    console.log('Error', response)
    Vue.notify({
      type: 'error',
      group: 'dojnaz',
      title: 'Error!',
      text: response.data.message
    })
  }
  return response
})

window.$router = router
window.$store = store
window.axios = axios

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.Vue = Vue

const index = 0
window.coms.exposed.log = (data) => store.commit('appendLog', {
  timestamp: Date.now(),
  content: data,
  index: index++
})

function log(data) {
  store.commit('appendLog', {
    timestamp: Date.now(),
    content: util.inspect(data),
    index: index++
  })
}
window.log = log
