<template>
  <b-navbar :style="{}">
    <template #brand v-if="!$store.state.isDesktop">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          :src="cloudLogo"
          alt="Dojnaz Cloud"
          :style="{
            maxHeight: '2.5rem',
          }"
        />
      </b-navbar-item>
    </template>
    <template #start v-if="$store.state.token">
      <b-navbar-item tag="router-link" to="/dashboard">
        Dashboard
      </b-navbar-item>
      <b-navbar-dropdown label="Surveys">
        <b-navbar-item tag="router-link" to="/dashboard/theoremreach">
          Theoremreach
        </b-navbar-item>
        <b-navbar-item tag="router-link" to="/dashboard/cpx">
          CPX Research
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item tag="router-link" to="/dashboard/mine">
        Mining
      </b-navbar-item>
    </template>

    <template #end>
      <inline-svg
        v-if="$store.state.vpnWarning"
        title="You may be using a VPN"
        :src="warning"
        :style="{
          paddingTop: '5px',
          cursor: 'pointer',
          height: '50px',
          width: '50px',
        }"
        @click="vpnWarning"
      />
      <b-navbar-item tag="p" v-if="$store.state.token" key="dfgohjosirt">
        <router-link to="/dashboard/history">
          {{ $store.state.balance }} coins
        </router-link>
      </b-navbar-item>
      <b-navbar-item>
        <div class="images" key="avatar">
          <img
            :src="$store.state.avatar"
            :style="{
              transform: 'scale(1.5)',
              borderRadius: '100%',
              marginTop: '7px',
              marginLeft: '4px',
              marginRight: '4px',
            }"
          />
        </div>
      </b-navbar-item>
      <b-navbar-item
        tag="div"
        :style="{
          paddingLeft: '0',
        }"
      >
        <div class="buttons" v-if="!$store.state.token" key="loginBtn">
          <router-link
            class="button is-primary"
            :style="{
              backgroundColor: '#1062FE',
            }"
            to="/login"
          >
            <strong>Login</strong>
          </router-link>
        </div>
        <template v-else>
          <div class="buttons" key="logoutBtn">
            <router-link
              class="button is-primary"
              :style="{
                backgroundColor: '#1062FE',
                marginLeft: '15px',
              }"
              to="/logout"
            >
              <strong>Logout</strong>
            </router-link>
          </div>
        </template>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import cloudLogo from "../assets/logo.png";
import warning from "../assets/warning.svg";
import VPNWarningModal from "./VPNWarningModal.vue";

export default {
  data() {
    return {
      cloudLogo,
      callback: process.env.VUE_APP_CALLBACK_URL,
      warning,
    };
  },
  mounted() {
    this.$store.dispatch("updateBalance");
  },
  methods: {
    vpnWarning() {
      this.$buefy.modal.open({
        parent: this,
        component: VPNWarningModal,
        hasModalCard: true,
        trapFocus: true,
      });
    },
  },
};
</script>

<style>
.navbar-end {
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>
