<template>
  <b-field label="Claim Voucher" label-position="on-border">
    <b-input placeholder="Voucher Code" v-model="voucherCode" />
    <p class="control">
      <b-button
        class="button is-primary"
        :style="{
          backgroundColor: '#1062FE',
        }"
        :loading="claiming"
        @click="claim"
      >
        Claim!
      </b-button>
    </p>
  </b-field>
</template>

<script>
import store from '../store'
import Vue from 'vue';
export default {
  data() {
    return {
      voucherCode: "",
      claiming: false
    };
  },
  methods: {
    claim() {
      this.claiming = true
      axios({
        method: 'POST',
        url: '/voucher/' + this.voucherCode
      }).then((response) => {
        console.log(response.data)
        store.dispatch('updateBalance', true)
      }).finally(() => {
        this.claiming = false
      })
    }
  }
};
</script>

<style>
</style>