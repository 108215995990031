var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{style:({}),scopedSlots:_vm._u([(!_vm.$store.state.isDesktop)?{key:"brand",fn:function(){return [_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ path: '/' }}},[_c('img',{style:({
          maxHeight: '2.5rem',
        }),attrs:{"src":_vm.cloudLogo,"alt":"Dojnaz Cloud"}})])]},proxy:true}:null,(_vm.$store.state.token)?{key:"start",fn:function(){return [_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/dashboard"}},[_vm._v(" Dashboard ")]),_c('b-navbar-dropdown',{attrs:{"label":"Surveys"}},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/dashboard/theoremreach"}},[_vm._v(" Theoremreach ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/dashboard/cpx"}},[_vm._v(" CPX Research ")])],1),_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/dashboard/mine"}},[_vm._v(" Mining ")])]},proxy:true}:null,{key:"end",fn:function(){return [(_vm.$store.state.vpnWarning)?_c('inline-svg',{style:({
        paddingTop: '5px',
        cursor: 'pointer',
        height: '50px',
        width: '50px',
      }),attrs:{"title":"You may be using a VPN","src":_vm.warning},on:{"click":_vm.vpnWarning}}):_vm._e(),(_vm.$store.state.token)?_c('b-navbar-item',{key:"dfgohjosirt",attrs:{"tag":"p"}},[_c('router-link',{attrs:{"to":"/dashboard/history"}},[_vm._v(" "+_vm._s(_vm.$store.state.balance)+" coins ")])],1):_vm._e(),_c('b-navbar-item',[_c('div',{key:"avatar",staticClass:"images"},[_c('img',{style:({
            transform: 'scale(1.5)',
            borderRadius: '100%',
            marginTop: '7px',
            marginLeft: '4px',
            marginRight: '4px',
          }),attrs:{"src":_vm.$store.state.avatar}})])]),_c('b-navbar-item',{style:({
        paddingLeft: '0',
      }),attrs:{"tag":"div"}},[(!_vm.$store.state.token)?_c('div',{key:"loginBtn",staticClass:"buttons"},[_c('router-link',{staticClass:"button is-primary",style:({
            backgroundColor: '#1062FE',
          }),attrs:{"to":"/login"}},[_c('strong',[_vm._v("Login")])])],1):[_c('div',{key:"logoutBtn",staticClass:"buttons"},[_c('router-link',{staticClass:"button is-primary",style:({
              backgroundColor: '#1062FE',
              marginLeft: '15px',
            }),attrs:{"to":"/logout"}},[_c('strong',[_vm._v("Logout")])])],1)]],2)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }